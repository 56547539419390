*{
    margin: 0;
    padding: 0;
}

.termsHeaderTitle{
    background-color: #FF6B35;
    margin-top: calc(13.6vh + 1rem);
    height: 25rem;
        display: grid;
        place-items: center;
    
      
}
.termsHeaderTitle h1{
    color: white !important;
    text-align: center;
    font-size: 5rem;  
    overflow: hidden;
}

  .containers{
    width: 85%;
    padding: 4rem 0;
    margin: 0 auto;
  }
  .termsDescription{
    font-size: 1.5rem;
    line-height: 1.625;
    color: #52525B;
  }
  .termsLink:hover,
  .termsLink:focus{
    color: #ff7011 !important;
  }
  ol{
    list-style-type: decimal !important;
    list-style: decimal !important;
    padding-left:0;
    color: #52525B;
  }
  ol li{
    margin-bottom: 20px;
    list-style: decimal !important;
    font-size: 1.5rem;
    line-height: 1.625;
    color: #52525B;
  }
  .listHeaders{
    color:black;
  }
  .listTitle{
    margin-top: 20px;
  }


  @media only screen and (max-width: 991px) {
    .termsHeaderTitle h1{
        font-size: 3.5rem;  
    }

  }

  @media only screen and (max-width: 769px) {
    ol li,
    .termsDescription{
        font-size: 1rem;
      }
    .termsHeaderTitle{
        height: 10rem;
    }
    .containers{
        padding: 2rem 0;
        font-size: 1rem;
      }
  }
  @media only screen and (max-width: 600px) {
    .termsHeaderTitle h1{
        font-size: 2.5rem;  
    }
  }
  @media only screen and (max-width: 400px) {
    .termsHeaderTitle h1{
        font-size: 2rem;  
    }
  }
  @media only screen and (max-width: 330px) {
    .termsHeaderTitle h1{
        font-size: 1.75rem;  
    }
  }